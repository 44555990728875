<template>
  <v-data-table
    :disabled="disabled"
    :headers="headers"
    :items="positions"
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #top>
      <v-toolbar color="transparent" dense flat>
        <v-spacer />
        <v-btn :disabled="disabled" color="primary" small @click="openDialog">
          {{ $t("add.item") }}
        </v-btn>
        <position-row-form
          v-model="dialog"
          :item="newItem"
          @save="saveItem"
          @close="onClose"
        />
      </v-toolbar>
    </template>

    <template #item="{ item, index, headers }">
      <position-row
        :headers="headers"
        :item="item"
        @remove="onRemovePosition(index)"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Inject, Mixins, Prop, Watch } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import {
  Contract,
  ContractPosition,
  ContractPositionData,
} from "@planetadeleste/vue-mc-gw";
import PositionRow from "@/modules/contracts/components/tabs/PositionRow.vue";
import { Ref } from "@/types/utils";
import { assign, findIndex, forEach, isEmpty, map } from "lodash";
import PositionRowForm from "./PositionRowForm.vue";

@Component({
  components: {
    PositionRow,
    PositionRowForm,
  },
})
export default class PositionsTable extends Mixins(DataTableMixin) {
  @Inject() readonly contract!: Ref<Contract>;
  @Inject() readonly defaultSettings!: Ref<Partial<ContractPositionData>>;
  @Prop(Boolean) readonly disabled!: boolean;
  headers: DataTableHeader[] = [];
  arHeaders: DataTableHeader[] = [
    { text: "invoice.type", value: "accounting_movement_type_id" },
    { text: "currency", value: "currency_id" },
    { text: "product", value: "product_id" },
    { text: "description", value: "name" },
    { text: "quantity", value: "quantity", width: 100 },
    { text: "unit.price", value: "price", width: 200 },
    { text: "taxtype", value: "tax_type_id" },
    { text: "payment.term", value: "payment_term_id" },
    { text: "from", value: "start_at", width: 100 },
    { text: "to", value: "end_at", width: 100 },
    // { text: "next", value: "next_date" },
  ];

  dialog = false;
  newItem: ContractPosition = new ContractPosition();

  openDialog() {
    this.dialog = true;
  }
  get obContract() {
    return this.contract?.value;
  }

  get obDefaultSettings() {
    return this.defaultSettings.value;
  }

  get useDefaultSettings(): boolean {
    return !isEmpty(this.obDefaultSettings);
  }

  get positions(): ContractPosition[] {
    return this.obContract
      ? map(this.obContract.get("positions", []), (obPosition) =>
          obPosition instanceof ContractPosition
            ? obPosition
            : new ContractPosition(obPosition)
        )
      : [];
  }

  @Watch("disabled")
  onChangeDisabled(bValue: boolean) {
    if (bValue || !this.obContract || this.positions.length) {
      return;
    }

    this.addEmptyPosition();
  }

  @Watch("useDefaultSettings")
  onChangeUseDefaultSettings() {
    this.onSetTableHeaders();
  }

  @Watch("obDefaultSettings", { deep: true })
  onChangeDefaultSettings(obData: Partial<ContractPositionData>) {
    if (isEmpty(obData) || !this.positions.length || !this.useDefaultSettings) {
      return;
    }

    forEach(this.positions, (obItem: ContractPosition) => {
      obItem.set(obData);
    });
  }

  addEmptyPosition() {
    this.newItem = new ContractPosition(
      assign({ quantity: 1, sort_order: 0 }, this.obDefaultSettings)
    );

    /*
    const arPositions = [...this.positions];
    const obPosition = new ContractPosition(
      assign(
        { quantity: 1, sort_order: arPositions.length + 1 },
        this.obDefaultSettings
      )
    );
    arPositions.push(obPosition);
    this.obContract.set("positions", arPositions);
    */
  }

  resetItem() {
    const arPositions = [...this.positions];
    this.newItem = new ContractPosition(
      assign(
        { quantity: 1, sort_order: arPositions.length + 1, price: 0 },
        this.obDefaultSettings
      )
    );
  }

  saveItem(item: ContractPosition) {
    const arPositions = [...this.positions];
    arPositions.push(item);
    this.obContract.set("positions", arPositions);
    this.dialog = false;
    this.resetItem();
  }

  onClose() {
    this.dialog = false;
    this.resetItem();
  }

  onRemovePosition(index: number) {
    if (this.positions.length <= index) {
      return;
    }

    const arPositions = [...this.positions];
    arPositions.splice(index, 1);
    this.obContract.set("positions", arPositions);
  }

  onSetTableHeaders() {
    const arDtHeaders = [...this.arHeaders];

    if (this.useDefaultSettings) {
      forEach(
        [
          "accounting_movement_type_id",
          "currency_id",
          "payment_term_id",
          "start_at",
          "end_at",
        ],
        (sKey) => {
          const idx = findIndex(arDtHeaders, { value: sKey });
          if (idx >= 0) {
            arDtHeaders.splice(idx, 1);
          }
        }
      );
    } else {
      const iProductIndex = findIndex(arDtHeaders, { value: "product_id" });
      if (iProductIndex >= 0) {
        arDtHeaders.splice(iProductIndex, 1);
      }
    }

    this.setDTHeaders(arDtHeaders).addDTActionsHeader().mapDTHeaders();
  }

  mounted() {
    this.onSetTableHeaders();
  }
}
</script>
